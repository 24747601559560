<template>
  <span>
    <v-card
      flat
      class="background_image"
      :img="background_img"
      >
      <div
        :class="toggle_bg_effect ? 'image_effect_animation': 'image_effect'"
      />
    </v-card>
  </span>
</template>
<script>
export default {
  data: () => ({

  }),
  props: {
    background_img: String,
    toggle_bg_effect: Boolean
  }
}
</script>
<style>
  .image_effect {
   width: 100%;
   height: 100%;
   min-height: 100%;
   max-height: 100%;
   position: fixed;
   background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(33, 52, 92, 1));
  }
 .background_image {
   width: 100%;
   height: 100%;
   min-height: 100%;
   max-height: 100%;
   z-index: 0;
   position: fixed;
  }
  .image_effect_animation {
    margin: 0;
    width: 100%;
    height: 100vh;
    font-family: "Exo", sans-serif;
    color: #fff;
    background: linear-gradient(-45deg,  rgb(33, 52, 92), rgba(33, 52, 92, 0.502));
    /*     background: linear-gradient(-45deg, rgba(255, 255, 255, 1),  rgba(255, 255, 255, 0.674)); */
    background-size: 400% 400%;
    animation: gradientBG 8s ease infinite;
  }

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
</style>